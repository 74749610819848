.input-with-mask {
  background: #f4f4f8;
  border-radius: 8px;

  border: 1px solid #f4f4f8;
  border-radius: 8px;
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 160%;
  color: #212121;
  width: 100%;
  padding: 15px 20px;
  outline: none;
  &.error {
    border: 1px solid #ed0a34;
    &:hover,
    &:focus {
      border: 1px solid #ed0a34;
    }
  }
  &:hover {
    border: 1px solid #d1d8e1;
  }
  &::placeholder {
    color: #94a0b1;
  }
  &:focus {
    border: 1px solid #7027c2;
  }
  &:read-only {
    background: #d1d8e1;
    border: 1px solid #d1d8e1;
    cursor: default;
    &:hover {
      border: 1px solid #d1d8e1;
    }
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
