.animte-menu {
  transition: all 0.3s cubic-bezier(0.63, 0.21, 0.37, 0.95);
}

aside.aside_menu.closed {
  padding-left: 32px;
  padding-right: 32px;
  width: 88px;
  .inner-logo {
    #inner-logo-colors {
      left: -3px;
      width: 30px;
      .inner-logo-color {
        img {
          position: absolute;
          display: block;
          left: 0;
          top: 0;
        }
        &:nth-child(2) {
          width: 0px;
          img {
            left: -15px;
          }
        }
        &:nth-child(3) {
          img {
            left: auto;
            right: 0;
          }
        }
      }
    }
    #logo-inner-image {
      left: -20px;
      opacity: 0;
      visibility: hidden;
    }
  }
  .inner-menu-list {
    span {
      opacity: 0;
      visibility: hidden;
    }
  }
  button.button_logout {
    span {
      opacity: 0;
      visibility: hidden;
    }
  }
}

aside.aside_menu {
  border-right: 1px solid #f4f4f8;
  box-sizing: border-box;
  height: 100vh;
  left: 0;
  overflow: hidden;
  padding: 32px 40px 40px;
  position: fixed;
  top: 0;
  width: 240px;
  z-index: 10;
  // overflow: auto;
  display: block;
  // @media (max-height: 700px) {
  //   & {
  //     /* хром, сафари */
  //     &::-webkit-scrollbar {
  //       width: 0;
  //     }

  //     /* ie 10+ */
  //     & {
  //       -ms-overflow-style: none;
  //     }
  //   }
  // }
  .inner-logo {
    margin-bottom: 48px;
    position: relative;
    display: block;
    #inner-logo-colors {
      display: flex;
      justify-content: center;
      left: 90px;
      position: absolute;
      top: 0;
      width: 67px;
      .inner-logo-color {
        display: block;
        height: 30px;
        left: 0;
        overflow: hidden;
        position: relative;
        top: 0;
        width: 15px;
        img {
          position: absolute;
          display: block;
          left: 0;
          top: 0;
        }
        &:nth-child(2) {
          width: 37px;
          img {
            left: -15px;
          }
        }
        &:nth-child(3) {
          img {
            left: auto;
            right: 0;
          }
        }
      }
    }
    img {
      display: block;
      left: 0;
      position: relative;
    }
  }
  .inner-menu-list {
    list-style-type: none;
    &__item.active {
      color: #7027C2;
    }
    &__item {
      a {
        color: #212121;
        display: block;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 18px;
        padding-left: 36px;
        position: relative;
        text-decoration: none;
        font-family: "Averta CY";
        svg {
          position: absolute;
          left: 0;
          top: 0;
        }
        &:hover {
          color: #7027c2;
          path {
            fill: #7027c2;
          }
        }

        span {
          display: block;
          opacity: 1;
          position: relative;
          transition-property: opacity, visibility;
          visibility: visible;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &.active {
        a {
          color: #7027c2;
        }
        path {
          fill: #7027c2;
        }
      }
    }
  }
  button.button_logout {
    position: absolute;
    bottom: 40px;
    color: #212121;
    display: block;
    font-size: 15px;
    background: transparent;
    border: none;
    // left: 40px;
    line-height: 24px;
    padding-left: 40px;
    text-decoration: none;
    cursor: pointer;

    transition-property: opacity, left;
    // @media (max-height: 700px) {
    //   & {
    //     margin-top: 80px;
    //     position: relative;
    //     bottom: auto;
    //   }
    // }
    svg {
      position: absolute;
      left: 0;
      top: 0;
    }
    &:hover {
      color: #7027C2;
      path {
        fill: #7027C2;
      }
    }
    span {
      display: block;
      opacity: 1;
      position: relative;
      transition-property: opacity, visibility;
      visibility: visible;
    }
  }
}
