.personal-area-section.closed {
  margin-left: 88px;
  .main-loader-wrapper {
    left: calc(88px);
    width: calc(100vw - 88px);
  }
  #fixed-statistics-itog {
    width: calc(100% - 88px) !important;
  }
}

@keyframes dropSaveInfo {
  0% {
    margin-top: -48px;
  }

  10% {
    margin-top: 0px;
  }
  90% {
    margin-top: 0px;
  }
  100% {
    margin-top: -48px;
  }
}
#block-on-mobile {
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff url("/public/images/mobile.png") no-repeat 50% 50%;
  background-size: 327px auto;
  z-index: 9999;
  #mobile-logo {
    position: absolute;
    display: block;
    top: 24px;
    left: calc(50% - 80px);
  }
  @media screen and (max-width: 1024px) {
    display: block;
  }
  @media screen and (max-height: 400px) {
    #mobile-logo {
      display: none;
    }
  }
}
section.personal-area-item.edit {
  .container-header {
    margin-bottom: 40px;
  }
}

.action-button {
  border: none;
  font-family: "Averta CY";
}
button.button:disabled,
.button_add-category:disabled {
  background: #d1d8e1 !important;
  color: #94a0b1 !important;
  pointer-events: none !important;
}

.save-info-block {
  position: fixed;
  width: 100vw;
  height: 48px;
  background: #7027c2;
  top: 0;
  left: 0;
  z-index: 9999;
  margin-top: -48px;
  &.active {
    animation-duration: 3s;
    animation-name: dropSaveInfo;
  }
  span {
    position: absolute;
    width: 81px;
    height: 24px;
    left: calc(50% - 81px / 2 - 0.5px);
    top: calc(50% - 24px / 2);
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 160%;
    text-align: center;
    color: #ffffff;
  }
}

.check-button-item {
  cursor: pointer;
  display: block;
  font-size: 15px;
  height: 24px;
  line-height: 24px;
  position: relative;
  &::before {
    background: #f4f4f8;
    border: 1px solid #d1d8e1;
    border-radius: 4px;
    box-sizing: border-box;
    content: "";
    display: block;
    height: 24px;
    left: 0;
    position: absolute;
    top: calc(50% - 12px);
    width: 24px;
  }

  &.selected {
    &::before {
      background: #7027c2 url("/public/images/icons/ui/forms/white/check.svg") no-repeat 50% 50%;
      border-color: #7027c2;
    }
  }
  &:hover {
    span {
      color: #7027c2;
    }
  }
  span {
    display: block;
    font-weight: 400;
    overflow: hidden;
    padding-left: 38px;
    position: relative;
    width: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.personal-area-section {
  background: #f4f4f8;
  display: block;
  margin-left: 240px;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  background: #f4f4f8 url("/public/images/innerbg.png") no-repeat 0 0;
  // background-attachment: fixed;
  &.modal {
    overflow: hidden !important;
    height: 100vh;
  }
  background-size: 100% auto;
  .main-loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: calc(240px);
    width: 100%;
    width: calc(100vw - 240px);
    height: 100vh;
    box-sizing: border-box;
    background: transparent;
    border-radius: 16px;
    &.passive {
      display: none;
    }
    img.main-loader-img {
      position: relative;
      display: block;
      width: 50px;
      height: 50px;
      &::after {
        background-size: 50px 50px;
        content: "";
        display: block;
        height: 50px;
        pointer-events: none;
        width: 50px;
        animation: loaderAnimation 1s linear infinite;
        background: url("/public/images/icons/ui/status/blue/loader.svg") no-repeat;
        background-size: contain;
      }
    }
  }
  section {
    padding: 0 40px 60px;
    display: block;
    position: relative;
    min-height: 100vh;
    overflow-x: hidden;
    padding-top: 104px;
  }
  .input-field {
    display: block;
    position: relative;
    .button_clear-select {
      cursor: pointer;
      height: 24px;
      position: absolute;
      right: 44px;
      top: 14px;
      width: 24px;
      background: transparent;
      border: none;
      z-index: 1111;
    }
    .select-block.multiselect {
      background: #f4f4f8;
      border: 1px solid #f4f4f8;
      border-radius: 8px;
      cursor: pointer;
      display: block;
      font-size: 15px;
      height: 54px;
      outline: none;
      position: relative;
      &:hover {
        border: 1px solid #d1d8e1;
      }
      .select-block-button {
        font-size: 15px;
        line-height: 54px;
        padding: 0 20px;
        span {
          display: block;
          overflow: hidden;
          position: absolute;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: calc(100% - 60px);
        }
        &::after {
          background: url("/public/images/icons/ui/navigation/drop_down_filled.svg") no-repeat;
          content: "";
          display: block;
          height: 24px;
          pointer-events: none;
          position: absolute;
          right: 16px;
          top: 16px;
          width: 24px;
        }
      }

      .select-block-list {
        background: #fff;
        border-radius: 8px;
        box-shadow: 8px 8px 48px rgb(46 69 102 / 20%);
        cursor: default;
        display: none;
        overflow: hidden;
        position: absolute;
        top: calc(100% + 8px);
        width: 100%;
        z-index: 100;
        .select-block-list-scroll {
          max-height: 246px;
          display: block;
          overflow-y: scroll;
          position: relative;
          width: calc(100% + 20px);
          .select-block-item {
            position: relative;
            cursor: pointer;
            display: block;
            font-size: 15px;
            height: 40px;
            line-height: 40px;
            padding: 0 20px;
            span {
              padding-left: 38px;
              width: calc(100% - 100px);
              display: block;
              overflow: hidden;
              position: absolute;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            span:hover {
              color: #7027c2;
            }
            &:first-child {
              margin-top: 16px;
            }
            &:hover {
              background: #f4f4f8;
            }
            &::before {
              background: #f4f4f8;
              border: 1px solid #d1d8e1;
              border-radius: 4px;
              box-sizing: border-box;
              content: "";
              display: block;
              height: 24px;
              left: 20px;
              position: absolute;
              top: calc(50% - 12px);
              width: 24px;
            }
          }
          .select-block-item.selected {
            position: relative;
            cursor: pointer;
            display: block;
            font-size: 15px;
            height: 40px;
            line-height: 40px;
            padding: 0 20px;
            span {
              padding-left: 38px;
              width: calc(100% - 100px);
              display: block;
              overflow: hidden;
              position: absolute;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            &::before {
              border: 1px solid #d1d8e1;
              background: #7027c2 url("/public/images/icons/ui/forms/white/check.svg") no-repeat 50% 50%;
              border-color: #7027c2;
              border-radius: 4px;
              box-sizing: border-box;
              content: "";
              display: block;
              height: 24px;
              left: 20px;
              position: absolute;
              top: calc(50% - 12px);
              width: 24px;
            }
          }
        }
        .select-block-control {
          border-top: 1px solid #e2e8f0;
          display: flex;
          height: 44px;
          justify-content: space-between;
          position: relative;
          button.button-select {
            position: relative;
            background: transparent;
            border: none;
            outline: none;
            cursor: pointer;
            font-size: 15px;
            line-height: 44px;
            padding: 0 20px;
            font-family: "Averta CY";
          }
          .select-block-cancel {
            color: #94a0b1;
          }
          .select-block-select {
            color: #7027c2;
          }
        }
      }
    }
    .select-block.multiselect.active {
      .select-block-list {
        display: block;
      }
    }
  }
}

.flex-block.flex-start {
  justify-content: flex-start;
}

.form-container-blocks .form-container .form-container-block .form-container-block-title {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 32px;
  position: relative;
}

.flex-block .flex-block-column {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 8px;
  position: relative;
  width: 100%;
}

h2 {
  font-size: 32px;
  line-height: 37px;
  margin: 0;
  padding: 0;
  font-weight: 600;
}
.input-field,
.modal-content .modal-field .input-field {
  .clear-chosen-container {
    width: 24px;
    height: 24px;
    right: 44px;
    top: calc(50% - 12px);
    cursor: pointer;
    display: none;
    position: absolute;
    border: none;
    outline: none;
    background: transparent;
    svg {
      width: 24px;
      height: 24px;
    }
    &.active {
      display: block;
    }
  }
  .search-wrapper {
    // react-select styles
    &:focus {
      border: 1px solid #d1d8e1 !important;
    }

    // span,
    .select__value-container {
      .select__placeholder {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .select__indicators {
      // display: none;
      .select__indicator-separator {
        display: none;
      }
      .select__indicator.select__dropdown-indicator {
        display: none;
      }
    }
    .select__control--is-focused {
      border: 1px solid #d1d8e1 !important;
      box-shadow: none;
    }
    &.error {
      .select__control {
        border: 1px solid #ed0a34;
      }
    }
    .select__control {
      background: #f4f4f8;
      border: 1px solid #f4f4f8;
      border-radius: 8px;
      box-sizing: border-box;
      color: #212121 !important;
      font-family: "Averta CY";
      font-size: 15px;
      height: auto;
      min-height: 54px;
      outline: none;
      padding: 11px 45px 8px 16px;
      position: relative;
      width: 100% !important;
      display: flex;
      cursor: pointer;
      .select__placeholder {
        font-weight: 400;
        font-size: 15px;
        color: #212121;
      }
      .select__value-container {
        padding: 0;
      }

      &:hover {
        border: 1px solid #d1d8e1;
      }
      &::after {
        background: url("/public/images/icons/ui/navigation/drop_down_filled.svg") no-repeat;
        content: "";
        display: block;
        height: 24px;
        pointer-events: none;
        position: absolute;
        right: 16px;
        top: 14px;
        width: 24px;
        margin: 0;
        padding: 0;
      }
      input {
        font-family: "Averta CY";
        font-size: 15px;
        cursor: text;
        height: auto;
        margin: 0;
        overflow: hidden;
        padding: 0;
        position: relative;
        max-width: 100%;
        width: 100%;
        display: inline-block;
        &:focus {
          border: none;
        }
        &:hover {
          border: none;
        }
      }
      span {
        background: #d1d8e1;
        border-radius: 6px;
        box-sizing: border-box;
        color: #212121;
        cursor: pointer;
        font-size: 15px;
        line-height: 29px;
        margin-bottom: 4px;
        margin-right: 4px;
        max-width: 100%;
        padding: 0 24px 0 8px;
        position: relative;
        i {
          display: block;
          font-size: 1px;
          height: 16px;
          position: absolute;
          right: 5px;
          top: 6px;
          width: 16px;
          svg {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
    .select__menu {
      background: #fff;
      border-radius: 8px;
      border-top: 0;
      box-shadow: 8px 8px 48px rgb(46 69 102 / 20%);
      // top: calc(100% + 8px);
      overflow: hidden;
      z-index: 1010;

      div {
        color: #444;
        margin: 0;
        max-height: 240px;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0;
        position: relative;
        width: calc(100% - 8px);
        padding: 16px 0 16px 0;
        scrollbar-width: thin;
        scrollbar-color: rgba($color: #94a0b1, $alpha: 0.3) transparent;
        scrollbar-gutter: auto;
        &::-webkit-scrollbar {
          border-radius: 2px;
          display: block;
          height: calc(100% - 32px);
          overflow: hidden;
          pointer-events: none;
          position: absolute;
          right: 8px;
          top: 16px;
          width: 4px;
          cursor: default;
          pointer-events: none;
        }
        &::-webkit-scrollbar-track-piece {
          &:start {
            background: transparent;
          }
        }
        &::-webkit-scrollbar-thumb {
          background: #94a0b160;
          border-radius: 2px;
          height: 30%;
          width: 100%;
          pointer-events: none;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
          margin: 16px 0;
        }
        div {
          cursor: pointer;
          display: list-item;
          font-size: 15px;
          height: 40px;
          line-height: 40px;
          padding: 0 20px;
          width: 100%;
          position: relative;
          &:hover {
            color: #7027c2;
          }
          &:hover {
            background: #f4f4f8;
          }
        }
      }
    }
  }
}

.bookmark-wrapper {
  // universal
  display: flex;
  border-bottom: 1px solid #e2e8f0;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 29px;
  margin-top: 23px;
  .bookmarks {
    display: flex;
    gap: 32px;
    position: relative;
    button.bookmark {
      border: none;
      border-bottom: 2px solid transparent;
      outline: none;
      background: transparent;
      align-items: center;
      box-sizing: border-box;
      color: #212121;
      font-family: "Averta CY";
      display: block;
      font-size: 15px;
      font-weight: 400;
      line-height: 160%;
      padding-bottom: 18px;
      position: relative;
      cursor: pointer;
      &.video-edit-bookmark {
        width: auto;
        color: #212121;
        border-bottom: 2px solid transparent;
        &:hover {
          border-bottom: 2px solid transparent;
        }
        &.active {
          border-bottom: 2px solid #7027c2;
        }
      }
      &:hover {
        border-bottom: 2px solid #7027c2;
      }
      &.active {
        border-bottom: 2px solid #7027c2;
      }
    }
  }
  .list-title-selects {
    // margin-top: 13px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    white-space: nowrap;
    .list-title-date {
      font-weight: 400;
      font-size: 15px;
      line-height: 160%;
      color: #94a0b1;
    }
    .list-title-select {
      position: relative;
      display: block;
      font-weight: 400;
      font-size: 15px;
      line-height: 160%;
      color: #212121;
      .list-title-select-btn {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 15px;
        line-height: 24px;
        white-space: nowrap;
        font-weight: 400;
        svg {
          position: relative;
          display: inline-block;
          vertical-align: top;
        }
      }
      &:hover {
        .list-title-select-btn {
          color: #7027c2;
          svg {
            path {
              fill: #7027c2;
            }
          }
        }
      }
      &.active {
        .list-title-select-submenu {
          display: block;
        }
      }
      .list-title-select-submenu {
        position: absolute;
        display: none;
        right: 0;
        top: 32px;
        padding: 16px 20px;
        background: #ffffff;
        box-shadow: 8px 8px 48px rgba(46, 69, 102, 0.2);
        border-radius: 8px;
        z-index: 10;
        .list-title-select-submenu-item {
          position: relative;
          display: block;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          white-space: nowrap;
          cursor: pointer;
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
          &:hover {
            color: #7027c2;
          }
        }
      }
    }
  }
}

// modal

.modal-field {
  .input-search.input-icon {
    display: block;
    height: 20px;
    cursor: pointer;
    position: absolute;
    left: 16px;
    right: auto;
    top: 18px;
    width: 20px;
    background: url("/public/images/icons/ui/navigation/search.svg");
    background-size: contain;
  }
}

#fixed-statistics-itog {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 240px);
  bottom: 0;
  right: 0;
  height: 32px;
  z-index: 1;
  background: #f4f4f8;
  padding: 0 40px 0 48px;
  box-sizing: border-box;
  .fixed-staistics-data {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 24px;
    font-size: 13px;
    line-height: 32px;
  }
}
