.policy-page {
  padding: 32px 40px 32px 40px;
  text-align: justify;
  p {
    margin-top: 0.35cm;
  }
  //72px
  &__header {
    margin-bottom: 32px;
  }
  &__content {
    max-width: 966px;
  }
  &__title {
    font-weight: 600;
    font-size: 40px;
    line-height: 46px;
    margin-bottom: 32px;
  }
  h1 {
    font-size: 14px;
    font-weight: unset;
    line-height: 22px;
    overflow: unset;
    text-overflow: unset;
    white-space: unset;
  }
  b {
    font-weight: 600;
  }
  ul li {
    list-style-type: disc;
  }
  ul {
    margin-left: 48px;
  }
  .dash-list {
    margin-left: 40px;
  }
  .dash-list li {
    list-style: none;
  }
  button {
    border: none;
    background: none;
  }

  a {
    color: inherit;
    text-decoration: none;
    border-bottom: rgba(0,0,0,.2) solid 1px;
    &:hover{
      border-bottom: rgba(0,0,0,1) solid 1px;
    }
  }

  ul,
  li {
    list-style: none;
  }

  input {
    -webkit-box-shadow: inherit;
    -webkit-appearance: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f4f4f8 inset !important;
    &::first-line {
      font-family: "Averta CY";
      font-weight: inherit;
      font-style: inherit;
      font-size: 15px;
    }
  }

  @-webkit-keyframes autofill {
    100% {
      background: transparent;
      color: inherit;
      font-size: inherit;
    }
  }

  /* Chrome, Safari, Edge, Opera */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  input[type="number"] {
    -moz-appearance: textfield;
  }

  sub,
  sup {
    /* Specified in % so that the sup/sup is the
       right size relative to the surrounding text */
    font-size: 75%;
    /* Zero out the line-height so that it doesn't
       interfere with the positioning that follows */
    line-height: 0;
    /* Where the magic happens: makes all browsers position
       the sup/sup properly, relative to the surrounding text */
    position: relative;
    /* Note that if you're using Eric Meyer's reset.css, this
       is already set and you can remove this rule */
    vertical-align: baseline;
  }

  sup {
    /* Move the superscripted text up */
    // top: -0.5em;
    left: -6px;
  }

  sub {
    /* Move the subscripted text down, but only
       half as far down as the superscript moved up */
    bottom: -0.25em;
  }
}
