* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
  body {
    color: #212121;
    font-family: "Averta CY";
  }
}

@keyframes loaderAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
